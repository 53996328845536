import React from 'react'
import Layout from '../components/Layout'

const IndexPage = ({ data, location }) => {
  return (
    <Layout location={location}>
      404 not found
    </Layout>
  )
}

export default IndexPage
